// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-archived-projects-js": () => import("./../../../src/pages/admin/archived-projects.js" /* webpackChunkName: "component---src-pages-admin-archived-projects-js" */),
  "component---src-pages-admin-new-team-js": () => import("./../../../src/pages/admin/new-team.js" /* webpackChunkName: "component---src-pages-admin-new-team-js" */),
  "component---src-pages-admin-new-track-js": () => import("./../../../src/pages/admin/new-track.js" /* webpackChunkName: "component---src-pages-admin-new-track-js" */),
  "component---src-pages-admin-team-js": () => import("./../../../src/pages/admin/team.js" /* webpackChunkName: "component---src-pages-admin-team-js" */),
  "component---src-pages-admin-tracks-js": () => import("./../../../src/pages/admin/tracks.js" /* webpackChunkName: "component---src-pages-admin-tracks-js" */),
  "component---src-pages-apply-applications-js": () => import("./../../../src/pages/apply/applications.js" /* webpackChunkName: "component---src-pages-apply-applications-js" */),
  "component---src-pages-apply-index-js": () => import("./../../../src/pages/apply/index.js" /* webpackChunkName: "component---src-pages-apply-index-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-auth-register-js": () => import("./../../../src/pages/auth/register.js" /* webpackChunkName: "component---src-pages-auth-register-js" */),
  "component---src-pages-auth-steps-js": () => import("./../../../src/pages/auth/steps.js" /* webpackChunkName: "component---src-pages-auth-steps-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-learning-content-learning-content-id-js": () => import("./../../../src/pages/dashboard/learning-content/[learningContentId].js" /* webpackChunkName: "component---src-pages-dashboard-learning-content-learning-content-id-js" */),
  "component---src-pages-dashboard-project-project-id-js": () => import("./../../../src/pages/dashboard/project/[projectId].js" /* webpackChunkName: "component---src-pages-dashboard-project-project-id-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentee-learning-content-js": () => import("./../../../src/pages/mentee/learning-content.js" /* webpackChunkName: "component---src-pages-mentee-learning-content-js" */),
  "component---src-pages-mentee-overview-js": () => import("./../../../src/pages/mentee/overview.js" /* webpackChunkName: "component---src-pages-mentee-overview-js" */),
  "component---src-pages-mentee-projects-js": () => import("./../../../src/pages/mentee/projects.js" /* webpackChunkName: "component---src-pages-mentee-projects-js" */),
  "component---src-pages-mentor-edit-learning-content-learning-content-id-js": () => import("./../../../src/pages/mentor/edit/learning-content/[learningContentId].js" /* webpackChunkName: "component---src-pages-mentor-edit-learning-content-learning-content-id-js" */),
  "component---src-pages-mentor-edit-project-project-id-js": () => import("./../../../src/pages/mentor/edit/project/[projectId].js" /* webpackChunkName: "component---src-pages-mentor-edit-project-project-id-js" */),
  "component---src-pages-mentor-learning-content-js": () => import("./../../../src/pages/mentor/learning-content.js" /* webpackChunkName: "component---src-pages-mentor-learning-content-js" */),
  "component---src-pages-mentor-new-learning-content-js": () => import("./../../../src/pages/mentor/new-learning-content.js" /* webpackChunkName: "component---src-pages-mentor-new-learning-content-js" */),
  "component---src-pages-mentor-new-project-js": () => import("./../../../src/pages/mentor/new-project.js" /* webpackChunkName: "component---src-pages-mentor-new-project-js" */),
  "component---src-pages-mentor-overview-js": () => import("./../../../src/pages/mentor/overview.js" /* webpackChunkName: "component---src-pages-mentor-overview-js" */),
  "component---src-pages-mentor-projects-js": () => import("./../../../src/pages/mentor/projects.js" /* webpackChunkName: "component---src-pages-mentor-projects-js" */),
  "component---src-pages-path-algorithms-js": () => import("./../../../src/pages/path/algorithms.js" /* webpackChunkName: "component---src-pages-path-algorithms-js" */),
  "component---src-pages-path-backend-js": () => import("./../../../src/pages/path/backend.js" /* webpackChunkName: "component---src-pages-path-backend-js" */),
  "component---src-pages-path-career-advice-js": () => import("./../../../src/pages/path/career-advice.js" /* webpackChunkName: "component---src-pages-path-career-advice-js" */),
  "component---src-pages-path-frontend-js": () => import("./../../../src/pages/path/frontend.js" /* webpackChunkName: "component---src-pages-path-frontend-js" */),
  "component---src-pages-path-fullstack-js": () => import("./../../../src/pages/path/fullstack.js" /* webpackChunkName: "component---src-pages-path-fullstack-js" */),
  "component---src-pages-path-mobile-js": () => import("./../../../src/pages/path/mobile.js" /* webpackChunkName: "component---src-pages-path-mobile-js" */),
  "component---src-pages-path-qa-js": () => import("./../../../src/pages/path/qa.js" /* webpackChunkName: "component---src-pages-path-qa-js" */),
  "component---src-pages-path-tech-advice-js": () => import("./../../../src/pages/path/tech-advice.js" /* webpackChunkName: "component---src-pages-path-tech-advice-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-why-pay-ksh-1500-js": () => import("./../../../src/pages/why-pay-ksh-1500.js" /* webpackChunkName: "component---src-pages-why-pay-ksh-1500-js" */)
}

